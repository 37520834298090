/*
 * @Author: 罗侠 luoxia@supos.com
 * @Date: 2021-04-15 09:50:15
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2023-04-04 16:33:28
 * @FilePath: \apps-ssr-frontend\src\layouts\BasicLayout.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { PureComponent } from 'react';
import { maintainSiteSource } from '@utils/helper';

import Header from '../components/Header';

import styles from './basicLayout.module.less';
const BasicLayout = () => (WrapedComponent) => {
  return class Layout extends PureComponent {
    constructor(props) {
      super(props);

      maintainSiteSource();
    }

    render() {
      const { props } = this;
      return (
        <div className={styles['basic-layout']}>
          <Header {...props} />
          <div className={styles.wraped}>
            <WrapedComponent {...props}></WrapedComponent>
          </div>
        </div>
      );
    }
  };
};

export default BasicLayout;
