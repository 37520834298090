/**
 * 通用延时函数
 * @param timeout
 * @returns {Promise<any>}
 */
export const delay = (timeout = 0.3) =>
  new Promise((resolve) => setTimeout(resolve, timeout * 1000));

/**
 * 讲枚举数组转化为枚举对象
 * @param arr
 * @param options {key: 'key',val: 'name'}
 * @returns {{}}
 */
export const exchangeToEnum = (
  arr = [],
  options = {
    key: 'id',
    val: 'name',
  }
) => {
  if (!Array.isArray(arr)) {
    console.error('第一个参数必须为数组');
    return {};
  }
  return arr.reduce((result, item) => {
    result[item[options.key]] = item[options.val];
    return result;
  }, {});
};

/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export function isArray(obj) {
  return Array.isArray(obj);
}

export function isFuc(f) {
  return typeof f === 'function';
}

/**
 * 是否为服务端
 */
export function isServer() {
  return typeof window === 'undefined';
}

/**
 *公用添加默认值的方法
 * @param data 对象 or 数组
 * @param defaultvalue 默认值（替换null和undefined）
 * @returns {Uint8Array | BigInt64Array | any[] | Float64Array | Int8Array | Float32Array | Int32Array | Uint32Array | Uint8ClampedArray | BigUint64Array | Int16Array | Uint16Array|{}|*}
 */
export function generateDefaultValue(data, defaultvalue = '--') {
  if (!isObject(data)) return data;
  const newData = new data.constructor();

  for (let key in Object.getOwnPropertyDescriptors(data)) {
    if (data[key] === null || data[key] === undefined || data[key] === '') {
      newData[key] = defaultvalue;
    } else {
      newData[key] = generateDefaultValue(data[key]);
    }
  }
  return newData;
}

/**
 * 深拷贝
 * @param data
 */
export function deepCopy(data) {
  if (!isObject(data)) return data;
  const newData = new data.constructor();

  for (let key in Object.getOwnPropertyDescriptors(data)) {
    newData[key] = deepCopy(data[key]);
  }
  return newData;
}

/**
 * 深度比较两个数是否相等
 * @param a
 * @param b
 * @returns {boolean}
 */
export function deepCompare(a, b) {
  if (!isObject(a) || !isObject(b)) {
    return a === b;
  }

  const propsA = Object.getOwnPropertyDescriptors(a);
  const propsB = Object.getOwnPropertyDescriptors(b);
  if (Object.keys(propsA).length !== Object.keys(propsB).length) {
    return false;
  }

  return Object.keys(propsA).every((key) => deepCompare(a[key], b[key]));
}

export const getCloudDomain = () => {
  if (isServer()) return;
  // let cloudDomain = '';
  // const hostname = window.location.hostname;

  // if (hostname.indexOf('test09') >= 0) {
  //   cloudDomain = 'http://cloud.test09.supos.com';
  // } else if (hostname.indexOf('test08') >= 0) {
  //   cloudDomain = 'http://cloud.test08.supos.com';
  // } else {
  //   cloudDomain = 'https://platform-cloud.supos.com';
  // }

  // return cloudDomain;
  return window.DOMAIN_CONFIG.platform;
};

/**
 * 获取url参数对象或 参数key对应的值
 * @param {*} url
 * @param {*} key
 */
export function getParams(url = window.location.href, key) {
  const paramsObj = {};
  const query = url.split('?')[1];
  // 如果没有参数直接返回
  if (!query) return key ? undefined : paramsObj;
  query.split('&').forEach((item) => {
    // eslint-disable-line
    const keyValue = item.split('=');
    paramsObj[keyValue[0]] = decodeURIComponent(keyValue[1]); // eslint-disable-line
  });
  return key ? paramsObj[key] : paramsObj;
}

// 检查是否是PlantMate跳转过来的链接
export const isPlantMate = () => {
  const { source, userId } = getParams();
  if (source && source.toUpperCase() === 'PLANTMATE' && userId) {
    return true;
  }
  return false;
};

export const getIsPlantMate = () => {
  if (isServer()) return false;

  const sitesource = sessionStorage.getItem('sitesource');

  if (sitesource && sitesource.toUpperCase() === 'PLANTMATE') {
    return true;
  } else {
    return false;
  }
};

// 评论时间转换  展示时间格式修改 评论时间小于24小时 就以小时为单位展示 大于24小时就以天为单位展示 如果大于365天就以年展示
export const evalTimeTransform = (createdTime) => {
  if (!createdTime) return '';

  let transformedTime = '';
  // 一年的毫秒数
  const yearMillisecond = 31536000000;
  // 一天的毫秒数
  const dayMillisecond = 86400000;
  // 一小时的毫秒数
  const hourMillisecond = 3600000;
  // 一分钟的毫秒数
  const minuteMillisecond = 60000;

  // 评论的时间毫秒数
  const createdTimeMillisecond = new Date(
    //createdTime.replace('-', '/')
    createdTime
  ).getTime();
  // 此时的毫秒数
  const todayMillisecond = new Date().getTime();
  // 评论至今的毫秒数
  const totalMillisecond = todayMillisecond - createdTimeMillisecond;

  if (totalMillisecond < hourMillisecond) {
    // 如果小于一小时就以分钟位单位展示
    const minutes = parseInt(totalMillisecond / minuteMillisecond, 10);
    if (minutes <= 3) {
      transformedTime = '刚刚';
    } else {
      transformedTime = `${minutes}分钟以前`;
    }
  } else if (totalMillisecond < dayMillisecond) {
    // 如果小于一天就以小时为单位展示
    const hours = parseInt(totalMillisecond / hourMillisecond, 10);
    transformedTime = `${hours}小时以前`;
  } else if (totalMillisecond < yearMillisecond) {
    // 如果小于一年就以天为单位展示
    const days = parseInt(totalMillisecond / dayMillisecond, 10);
    transformedTime = `${days}天以前`;
  } else {
    const years = parseInt(totalMillisecond / yearMillisecond, 10);
    transformedTime = `${years}年以前`;
  }

  return transformedTime;
};

// 金钱千分位格式化
export const thousandBitSeparator = (num) => {
  const decimal = String(num).split('.')[1] || ''; // 小数部分

  const tempArr = [];

  const revNumArr = String(num).split('.')[0].split('').reverse(); // 倒序

  for (const i in revNumArr) {
    tempArr.push(revNumArr[i]);

    if ((i + 1) % 3 === 0 && i !== revNumArr.length - 1) {
      tempArr.push(',');
    }
  }

  const zs = tempArr.reverse().join(''); // 整数部分

  return decimal ? `${zs}.${decimal}` : `${zs}.00`;
};

/**
 *
 * @param {*} array
 * @param {*} uniId  根据对象的那个字段进行去重
 */
export const uniqueObjArray = (array, uniId) => {
  const map = new Map();
  return array.filter(
    (item) => !map.has(item[uniId]) && map.set(item[uniId], 1)
  );
};

/**
 * 设置plantmate icon
 * @returns
 */
export const setPageIcon = () => {
  // 兼容服务端渲染
  if (isServer()) return;

  if (getIsPlantMate()) {
    // plantmate icon
    const link = 'https://plantmate.com/favicon.ico';

    let $favicon = document.querySelector('link[rel="icon"]');
    if ($favicon !== null) {
      $favicon.href = link;
    } else {
      $favicon = document.createElement('link');
      $favicon.rel = 'icon';
      $favicon.href = link;
      document.head.appendChild($favicon);
      document.title = 'PLANTMATE';
    }
  } else {
    document.title = '应用商店-工业APP-工业软件-让企业轻松上云';
  }
};

export const setVisitSource = () => {
  if (isServer()) return false;

  let sitesource =
    getParams(window.location.href, 'sitesource') ||
    getParams(document.referrer, 'sitesource');

  if (sitesource) {
    sessionStorage.setItem('sitesource', sitesource);
  } else {
    sessionStorage.setItem('sitesource', '');
  }
};

export const maintainSiteSource = () => {
  if (isServer()) return;

  const sitesource = sessionStorage.getItem('sitesource');
  const url = window.location.href;
  if (sitesource && url.indexOf('sitesource') < 0) {
    const newUrl = jointUrlParams(url, 'sitesource', 'plantmate');

    // // 监听页面刷新
    // window.onload = () => {
    //   window.history.pushState('', '', newUrl);
    // };

    window.history.pushState('', '', newUrl);
  }
};

export const envInit = () => {
  setVisitSource();
  setPageIcon();

  maintainSiteSource();
};

const jointUrlParams = (url, key, value) => {
  if (url.indexOf('?') >= 0) {
    url = url + `&${key}=${encodeURIComponent(value)}`;
  } else {
    url = url + `?${key}=${encodeURIComponent(value)}`;
  }

  return url;
};

/**
 *
 * @param {*} url 跳转地址
 * @param {*} type self 或者 blank
 */
export const locationPage = (url, type = 'self') => {
  const sitesource = sessionStorage.getItem('sitesource');

  let newUrl;

  if (sitesource && sitesource.toUpperCase() === 'PLANTMATE') {
    newUrl = jointUrlParams(url, 'sitesource', 'plantmate');
  } else {
    newUrl = url;
  }

  if (type === 'self') {
    window.location.href = newUrl;
  } else if (type === 'blank') {
    window.open(newUrl);
  }
};

// 获取云平台域名
export const getLanzhuoDomain = () => {
  if (isServer()) return;
  let domain = '';
  const href = window.location.href;

  if (href.indexOf('test09') >= 0) {
    domain = `http://cloud-dev.supos.com:19091`;
  } else if (href.indexOf('test08') >= 0) {
    domain = `http://cloud-test.supos.com:19091`;
  } else {
    domain = `https://cloud.supos.com`;
  }

  let key = 'customized-portal_config';
  let getLocal = localStorage.getItem(key) || null;
  let portalConfig = JSON.parse(getLocal) || {};

  if (portalConfig.userSideConfig) {
    return portalConfig.userSideConfig.accessAddressUrl;
  }
  return domain;
};

// 跳转登录页面
export const goLogin = () => {
  if (isServer()) return;

  const href = window.location.href;

  const target = `${DOMAIN_CONFIG.user}/login?siteFromUrl=${encodeURIComponent(
    href
  )}`;
  window.location.href = target;
};
